import React from "react"
import { Button, DatePicker, Modal, Radio } from "antd"
import moment from "moment"
import withMemo from "../../store/HOC/with_memo"
import { useAddCommunication } from "../../hooks/useAddCommunication"
import { ErrorMessage } from "../ErrorMessage"
import { ReactComponent as ClickIcon } from '../../assets/images/diaryEditBigger.svg'
import { CloseOutlined } from "@ant-design/icons"
import MiniQuill from '../editor/mini'


export const AddCommunications = withMemo(({ visible, ...rest }) => {
  const {
    closeDatePicker,
    handleClose,
    handleChangeRadio,
    radioStyle,
    data,
    setData,
    setOpenDatePicker,
    showDatePicker,
    setShowDatePicker,
    showWarning,
    handleClickIcon,
    pickerRef,
    inputRef,
    updateCommunicationAction,
    onChangeCommunicationQuill,
  } = useAddCommunication(rest, visible)
  return (
    <Modal
      title={<div style={{ padding: "15px 15px 5px 20px" }}>Convo</div>}
      open={visible}
      footer={null}
      centered
      style={{ zIndex: 2100 }}
      onCancel={handleClose}
      className="add-communication-modal"
      closable={false}
    >
      <div
        className="d-flex "
        style={{ position: "relative", justifyContent: "space-around" }}
      >
        <div className="w-100" style={{ maxWidth: "390px" }}>
          <MiniQuill editorRef={inputRef} content={data?.html_body} onChange={onChangeCommunicationQuill} />
          <div style={{ position: "absolute", top: -25, left: 75 }}>
            {showWarning && <ErrorMessage msg={"Cannot add an empty note"} />}
          </div>

        </div>
        {data.id &&
          <div className="convo-icon" style={{ position: "absolute", right: 50, top: -40, cursor: "pointer" }}>
            <ClickIcon onClick={handleClickIcon} />
          </div>
        }

        <CloseOutlined className="expand-icon" style={{ fontSize: "20px", position: "absolute", padding: "2px 2px 2px 2px", top: -40, right: 10 }} onClick={handleClose} />

        <div style={{ marginLeft: "1rem" }}>
          <Radio.Group
            onChange={handleChangeRadio}
            value={data.comm_type}
            className="d-flex flex-column"
          >
            <Radio style={radioStyle} value={1}>
              Email
            </Radio>
            <Radio style={radioStyle} value={2}>
              Phone
            </Radio>
            <Radio style={radioStyle} value={3}>
              Meeting
            </Radio>
            <Radio style={{ ...radioStyle, margin: 0 }} value={4}>
              LinkedIn
            </Radio>
          </Radio.Group>

        </div>
        <div style={{ zIndex: 10000, position: "absolute", right: 109, top: -40 }}>
          {showDatePicker ? (
            <DatePicker
              ref={pickerRef}
              onKeyDown={closeDatePicker}
              autoFocus
              style={{ borderRadius: "0px", height: "32px" }}
              onChange={(date) => {
                if (!date) {
                  setData({ ...data, comm_dt: moment().format("YYYY-MM-DD") })
                  setShowDatePicker(false)
                  return
                }
                setData({ ...data, comm_dt: date.$d })
                setOpenDatePicker(false)
                if (data.id) {
                  updateCommunicationAction({ id: data.id, comm_dt: date.$d })
                }
              }}
              format="YYYY-MM-DD"
              picker="date"
              showNow={false}
            />
          ) : (
            <Button
              style={{ borderRadius: "0px", height: "32px" }}
              onClick={() => {
                setShowDatePicker(true)
                setOpenDatePicker(true)
                setTimeout(() => pickerRef?.current?.nativeElement?.click(), 100)
              }}
            >
              Today
            </Button>
          )}
        </div>
      </div>

    </Modal>
  )
})
