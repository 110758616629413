import { createSelector } from "reselect"
export const getUser = (state) => state.user
export const getUserData = createSelector(getUser, (user) => {
  return user.user_data
})

export const getUserSubscriptionTypeSelector = createSelector(
  getUser,
  (user) => {
    return user?.user_data?.profile?.subscription_type
  }
)

export const getIsActionBill = createSelector(getUser, (user) => {
  return Boolean(
    user?.user_data?.profile?.redirect_profile?.next === "subscription"
  )
})

export const getIsInactiveAccount = createSelector(getUser, (user) => {
  return Boolean(
    user?.user_data?.profile?.redirect_profile?.next === "inactive"
  )
})

export const getActionFromRedirectProfile = createSelector(getUser, (user) => {
  return user?.user_data?.profile?.redirect_profile?.subscription_flags?.status
})

export const getUserStripeId = createSelector(getUser, (user) => {
  return user?.user_data?.profile?.stripe_customer_id
})

export const getStripeSecret = createSelector(getUser, (user) => {
  return user?.user_data?.profile?.stripe_client_secret
})

export const getUserStartGuide = createSelector(getUser, (user) => {
  return user?.user_data?.profile?.redirect_profile?.start_guide
})

export const getDesktopLayout = createSelector(getUserData, (user) => {
  return user?.profile?.desktop_layout
})
